import React from 'react';
import { useFormHandlers } from '../logic/EarlyAccessForm.logics';
import { industryOptions, regionOptions, Option } from '../../../data/optionsLists';
import '../styles/EarlyAccessForm.css';

interface FullEarlyAccessFormProps {
  handleFormSubmit: (formData: any) => void;
}

const FullEarlyAccessForm: React.FC<FullEarlyAccessFormProps> = ({ handleFormSubmit }) => {
  const { formData, handlers } = useFormHandlers(handleFormSubmit, undefined); // Pass 'undefined' for eventId

  const {
    confirmEmail,
    emailError,
    handleConfirmEmailChange,
    handleSubmit,
    renderTooltip,
    handleRegionChange,
    renderCountriesForSelectedRegion,
    handleSelectChange,
    handleTextChange,
    messageCharCount,
    messageCharLimit,
    charLimit,
    isCountryDisabled,
  } = handlers;

  return (
    <form className="company-form" onSubmit={handleSubmit}>
      {/* Company Details */}
      <fieldset className="form-section">
        <legend>Company Details</legend>
        <div className="form-row">
          <div className="input-group">
            <label>Company Name</label>
            <input
              type="text"
              name="companyName"
              className="input-company-name"
              value={formData.companyName}
              onChange={handleTextChange}
              required
              maxLength={charLimit}
            />
            {renderTooltip('companyName')}
          </div>
          <div className="input-group">
            <label>Website</label>
            <input
              type="text"
              name="website"
              className="input-website"
              value={formData.website}
              onChange={handleTextChange}
              required
              maxLength={charLimit}
            />
            {renderTooltip('website')}
          </div>
        </div>

        <div className="form-row">
          <div className="input-group">
            <label>Industry</label>
            <select
              name="industry"
              className="input-industry"
              value={formData.industry}
              onChange={handleSelectChange}
              required
            >
              <option value="">Select Industry</option>
              {industryOptions.map((option: Option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            {renderTooltip('industry')}
          </div>
          <div className="input-group">
            <label>Region</label>
            <select
              name="region"
              className="input-region"
              value={formData.region}
              onChange={handleRegionChange}
              required
            >
              <option value="">Select Region</option>
              {regionOptions.map((option: Option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            {renderTooltip('region')}
          </div>
          <div className="input-group">
            <label>Country</label>
            <select
              name="country"
              className="input-country"
              value={formData.country}
              onChange={handleSelectChange}
              required
              disabled={isCountryDisabled}
            >
              <option value="">{isCountryDisabled ? 'Select Region first' : 'Select Country'}</option>
              {renderCountriesForSelectedRegion()}
            </select>
            {renderTooltip('country')}
          </div>
        </div>
      </fieldset>

      {/* Contact Person */}
      <fieldset className="form-section">
        <legend>Contact Person</legend>
        <div className="form-row">
          <div className="input-group">
            <label>Salutation</label>
            <input
              type="text"
              name="title"
              className="input-title"
              value={formData.title}
              onChange={handleTextChange}
              required
              maxLength={charLimit}
            />
            {renderTooltip('title')}
          </div>
          <div className="input-group">
            <label>First Name</label>
            <input
              type="text"
              name="firstName"
              className="input-first-name"
              value={formData.firstName}
              onChange={handleTextChange}
              required
              maxLength={charLimit}
            />
            {renderTooltip('firstName')}
          </div>
          <div className="input-group">
            <label>Surname</label>
            <input
              type="text"
              name="surname"
              className="input-surname"
              value={formData.surname}
              onChange={handleTextChange}
              required
              maxLength={charLimit}
            />
            {renderTooltip('surname')}
          </div>
          <div className="input-group">
            <label>Job Role</label>
            <input
              type="text"
              name="jobRole"
              className="input-job-role"
              value={formData.jobRole}
              onChange={handleTextChange}
              required
              maxLength={charLimit}
            />
            {renderTooltip('jobRole')}
          </div>
        </div>

        <div className="form-row">
          <div className="input-group">
            <label>Country Code</label>
            <input
              type="text"
              name="countryCode"
              className="input-country-code"
              value={formData.countryCode}
              onChange={handleTextChange}
              required
              maxLength={charLimit}
            />
            {renderTooltip('countryCode')}
          </div>
          <div className="input-group">
            <label>Telephone</label>
            <input
              type="text"
              name="telephone"
              className="input-telephone"
              value={formData.telephone}
              onChange={handleTextChange}
              required
              maxLength={charLimit}
            />
            {renderTooltip('telephone')}
          </div>
          <div className="input-group">
            <label>Email</label>
            <input
              type="email"
              name="email"
              className="input-email"
              value={formData.email}
              onChange={handleTextChange}
              required
              style={{ color: emailError ? 'red' : 'black' }}
              maxLength={charLimit}
            />
            {renderTooltip('email')}
          </div>
          <div className="input-group">
            <label>Confirm Email</label>
            <input
              type="email"
              name="confirmEmail"
              className="input-confirm-email"
              value={confirmEmail}
              onChange={handleConfirmEmailChange}
              required
              style={{ color: emailError ? 'red' : 'black' }}
              maxLength={charLimit}
            />
            {renderTooltip('confirmEmail')}
          </div>
        </div>
      </fieldset>

      {/* Message Section */}
      <fieldset className="form-section">
        <legend>Message</legend>
        <div className="form-row full-width">
          <div className="input-group">
            <textarea
              name="message"
              className="input-message"
              value={formData.message}
              rows={5}
              onChange={handleTextChange}
              required
              maxLength={messageCharLimit}
            />
            {renderTooltip('message')}
            <div className="char-count">
              {messageCharCount}/{messageCharLimit} characters
            </div>
          </div>
        </div>
      </fieldset>

      {/* Submit Button */}
      <div className="form-row">
        <button type="submit" className="submit-btn">
          Submit
        </button>
      </div>
    </form>
  );
};

export default FullEarlyAccessForm;
