import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import '../styles/AppStaticFe.css'; // Global styles
import FooterSection from '../features/EarlyAccess/components/FooterSection';
import StickyHeader from '../features/EarlyAccess/components/StickyHeader';
import LandingPage from '../pages/LandingPage'; // Import LandingPage
import Documents from '../pages/Documents';
 
const AppStaticFe: React.FC = () => {
 
  return (
    <Router>
      <StickyHeader />
      <Routes>
        <Route path="/eventId/:eventId" element={<LandingPage />} />
        <Route path="/documents" element={<Documents /> } />
        <Route path="/" element={<LandingPage /> } />

        <Route path="*" element={<LandingPage /> } />

      </Routes>
      <FooterSection extended={false} />
    </Router>
  );
};

export default AppStaticFe;
