import hmac from 'crypto-js/hmac-sha256';
import encHex from 'crypto-js/enc-hex';
import { EarlyAccessData } from '../types/types';

export class EmailService {
  private workerEmailSenderUrl: string;
  private sharedSecret: string;

  constructor() {
    this.workerEmailSenderUrl = import.meta.env.VITE_WORKER_EMAIL_SENDER_URL || '';
    this.sharedSecret = import.meta.env.VITE_SHARED_SECRET || '';
  }

  // Generate HMAC token for authorization
  private generateHmacToken(): string {
    const timestamp = String(Math.floor(Date.now() / 1000));
    const message = `timestamp=${timestamp}`;
    const hmacToken = hmac(message, this.sharedSecret).toString(encHex);
    return `${hmacToken}:${timestamp}`;
  }

  // Send early access email
  // Send early access email
  async sendEarlyAccessEmail(formData: EarlyAccessData): Promise<boolean> {
    const today = new Date().toLocaleDateString();
    const emailUsername = formData.email.split('@')[0];
    
    // Compose email body based on eventId presence
    const emailContent = formData.eventId
      ? `
        Dear ${emailUsername},
        
        Thank you for joining our early access program.
        
        Here are your details:
        - Company Email: ${formData.email}
        - Event ID: ${formData.eventId}
        
        We will contact you within 15 working days to proceed.
        
        Best regards,
        The LauncherScanner Team
        `
      : `
        Dear ${formData.title} ${formData.firstName} ${formData.surname},
        
        Thank you for your interest in our exclusive early access program.
        
        Here are the details you provided:
        - Name: ${formData.firstName} ${formData.surname}
        - Job Title: ${formData.jobRole}
        - Email: ${formData.email}
        - Company: ${formData.companyName}
        - Industry: ${formData.industry}
        - Region: ${formData.region}
        - Country: ${formData.country}
        - Telephone: ${formData.countryCode} ${formData.telephone}
        - Message: ${formData.message}
        
        We received your information on ${today}.
        
        We are thrilled that you want to be a part of our community.
        We will contact you for a dedicated onboarding procedure in our exclusive network within 15 working days.
        If you need urgent assistance or experience any delays, reach out to us at info@launcherscanner.com.
        
        By submitting the form, you confirmed your consent to our Privacy Notice and Terms and Conditions:
        - Privacy Notice: https://www.launcherscanner.com/privacy.html
        - Terms and Conditions: https://www.launcherscanner.com/terms.html
        
        If you no longer wish to receive communications, email from ${formData.email} to unsubscribe@launcherscanner.com.
        
        Best regards,
        The LauncherScanner Team
        `;

    const hmacToken = this.generateHmacToken(); // Generate HMAC token

    // Prepare email data
    const emailData = {
      from: 'info@launcherscanner.com',
      to: formData.email,
      bcc: 'info@launcherscanner.com',
      subject: `Thank you for joining ${formData.companyName}'s early access`,
      body: emailContent,
    };

    try {
      const response = await fetch(this.workerEmailSenderUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${hmacToken}`,
        },
        body: JSON.stringify(emailData),
      });

      if (!response.ok) {
        const errorResponse = await response.json();
        console.error(`Failed to send email: ${response.statusText} - ${JSON.stringify(errorResponse)}`);
        return false;
      }

      console.log('Welcome email sent successfully!');
      return true;
    } catch (error) {
      console.error('Error while sending welcome email:', error);
      return false;
    }
  }
}
