// optionsList.ts
export interface Option {
    value: string;
    label: string;
  }
  
  export const industryOptions: Option[] = [
    { value: 'Space Launch Services', label: 'Space Launch Services' },
    { value: 'Payload Manufacturing', label: 'Payload Manufacturing' },
    { value: 'Payload Operations', label: 'Payload Operations' },
    { value: 'Insurance Provider', label: 'Insurance Provider' },
    { value: 'Logistics for Space', label: 'Logistics for Space' },
    { value: 'Investor', label: 'Investor' },
  ];
  
  export const regionOptions: Option[] = [
    { value: 'North America', label: 'North America' },
    { value: 'Europe', label: 'Europe' },
    { value: 'Asia Pacific', label: 'Asia Pacific' },
    { value: 'Middle East and North Africa', label: 'Middle East and North Africa' },
    { value: 'Latin America', label: 'Latin America' },
    { value: 'Sub-Saharan Africa', label: 'Sub-Saharan Africa' },
    { value: 'Australia and Oceania', label: 'Australia and Oceania' },
  ];
  
  
  export interface Region {
    region: string;
    countries: Option[];
  }
  
  
  
  
  
  
  export interface Region {
    region: string;
    countries: Option[];
  }
  
  export const regionCountryOptions: Region[] = [
    {
      region: 'North America',
      countries: [
        { value: 'US', label: 'United States' },
        { value: 'CA', label: 'Canada' },
        { value: 'MX', label: 'Mexico' },
      ],
    },
    {
      region: 'Europe',
      countries: [
        { value: 'AT', label: 'Austria' },
        { value: 'BE', label: 'Belgium' },
        { value: 'BG', label: 'Bulgaria' },
        { value: 'HR', label: 'Croatia' },
        { value: 'CY', label: 'Cyprus' },
        { value: 'CZ', label: 'Czech Republic' },
        { value: 'DK', label: 'Denmark' },
        { value: 'EE', label: 'Estonia' },
        { value: 'FI', label: 'Finland' },
        { value: 'FR', label: 'France' },
        { value: 'DE', label: 'Germany' },
        { value: 'GR', label: 'Greece' },
        { value: 'HU', label: 'Hungary' },
        { value: 'IE', label: 'Ireland' },
        { value: 'IT', label: 'Italy' },
        { value: 'LV', label: 'Latvia' },
        { value: 'LT', label: 'Lithuania' },
        { value: 'LU', label: 'Luxembourg' },
        { value: 'MT', label: 'Malta' },
        { value: 'NL', label: 'Netherlands' },
        { value: 'PL', label: 'Poland' },
        { value: 'PT', label: 'Portugal' },
        { value: 'RO', label: 'Romania' },
        { value: 'SK', label: 'Slovakia' },
        { value: 'SI', label: 'Slovenia' },
        { value: 'ES', label: 'Spain' },
        { value: 'SE', label: 'Sweden' },
      ],
    },
    {
      region: 'Asia Pacific',
      countries: [
        { value: 'CN', label: 'China' },
        { value: 'JP', label: 'Japan' },
        { value: 'IN', label: 'India' },
        { value: 'KR', label: 'South Korea' },
        { value: 'AU', label: 'Australia' },
        { value: 'NZ', label: 'New Zealand' },
        { value: 'SG', label: 'Singapore' },
        { value: 'MY', label: 'Malaysia' },
        { value: 'TH', label: 'Thailand' },
        { value: 'VN', label: 'Vietnam' },
      ],
    },
    {
      region: 'Middle East and North Africa',
      countries: [
        { value: 'AE', label: 'United Arab Emirates' },
        { value: 'SA', label: 'Saudi Arabia' },
        { value: 'EG', label: 'Egypt' },
        { value: 'MA', label: 'Morocco' },
        { value: 'IL', label: 'Israel' },
        { value: 'QA', label: 'Qatar' },
        { value: 'KW', label: 'Kuwait' },
        { value: 'OM', label: 'Oman' },
      ],
    },
    {
      region: 'Latin America',
      countries: [
        { value: 'BR', label: 'Brazil' },
        { value: 'AR', label: 'Argentina' },
        { value: 'CL', label: 'Chile' },
        { value: 'CO', label: 'Colombia' },
        { value: 'PE', label: 'Peru' },
        { value: 'UY', label: 'Uruguay' },
        { value: 'VE', label: 'Venezuela' },
        { value: 'EC', label: 'Ecuador' },
      ],
    },
    {
      region: 'Sub-Saharan Africa',
      countries: [
        { value: 'ZA', label: 'South Africa' },
        { value: 'NG', label: 'Nigeria' },
        { value: 'KE', label: 'Kenya' },
        { value: 'GH', label: 'Ghana' },
        { value: 'ET', label: 'Ethiopia' },
        { value: 'TZ', label: 'Tanzania' },
        { value: 'UG', label: 'Uganda' },
        { value: 'ZM', label: 'Zambia' },
      ],
    },
    {
      region: 'Australia and Oceania',
      countries: [
        { value: 'AU', label: 'Australia' },
        { value: 'NZ', label: 'New Zealand' },
        { value: 'FJ', label: 'Fiji' },
        { value: 'PG', label: 'Papua New Guinea' },
        { value: 'SB', label: 'Solomon Islands' },
        { value: 'TO', label: 'Tonga' },
      ],
    },
  ];
  
  