import React from 'react';
import { useFormHandlers } from '../logic/EarlyAccessForm.logics';
import '../styles/EarlyAccessForm.css';

interface MinimalFormProps {
  handleFormSubmit: (formData: any) => void;
  eventId: string;
}

const MinimalEarlyAccessForm: React.FC<MinimalFormProps> = ({ handleFormSubmit, eventId }) => {
  // Pass both handleFormSubmit and eventId to useFormHandlers
  const { formData, handlers } = useFormHandlers(handleFormSubmit, eventId);

  const { 
    emailError, 
    handleSubmit, 
    renderTooltip,
    handleTextChange, 
    charLimit,
  } = handlers;

  return (
    <form className="company-form" onSubmit={handleSubmit}>
      <fieldset className="form-section">
        <legend>Company Details</legend>
        <div className="form-row">
          <div className="input-group">
            <label>Company Email</label>
            <input
              type="email"
              name="email"
              className="input-email"
              value={formData.email}
              onChange={handleTextChange}
              required
              style={{ color: emailError ? 'red' : 'black' }}
              maxLength={charLimit}
            />
            {renderTooltip('email')}
            {emailError && <span className="error-tooltip">{emailError}</span>}
          </div>
        </div>
      </fieldset>

      <div className="form-row">
        <button type="submit" className="submit-btn">
          Submit
        </button>
        <input
          type="text"
          className="event-id-textbox"
          value={`Event ID: ${eventId}`}
          disabled
        />
      </div>
    </form>
  );
};

export default MinimalEarlyAccessForm;
