import React from 'react';
import documentPDF from '../assets/launcherscanner_IAC.pdf'; // Ensure this PDF is in the assets folder
import documentImage from '../assets/launcherscanner_IAC.jpeg'; // Ensure this JPG is in the assets folder

import '../styles/Documents.css'; // Styles for the Documents page

const Documents: React.FC = () => {
  return (
    <div className="documents-container">
      <h1>IAC 2024</h1>

      <div className="document-content">
        {/* Display the image */}
        <img src={documentImage} alt="Document Preview" className="document-image" />
        
        {/* PDF download link */}
        <p>You can download the document by clicking the link below:</p>
        <a href={documentPDF} download="launcherscanner_IAC.pdf" className="download-link">
          Download Launcherscanner IAC PDF
        </a>
      </div>
    </div>
  );
};

export default Documents;
