// validationMiddleware.ts
export const validateEmail = (email: string): string | null => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const blacklistedDomains = ['gmail.com', 'yahoo.com', '10minutemail.com'];
    const domain = email.split('@')[1];
  
    if (!emailPattern.test(email)) {
      return 'Invalid email format';
    } else if (blacklistedDomains.includes(domain)) {
      return 'Email domain not allowed';
    }
    return null; // Valid email
  };
  

  // Validates the telephone number (including country code)
export const validateTelephone = (telephone: string): string | null => {
  const phonePattern = /^\d{7,14}$/; // Only allow 7 to 14 digits for the telephone number (without country code)

  if (!phonePattern.test(telephone)) {
    return 'Invalid format: telephone number should be between 7 and 14 digits';
  }
  return null; // Valid telephone
};

// Validates the country code
export const validateCountryCode = (countryCode: string): string | null => {
  const countryCodePattern = /^(?:\+|00)\d{1,3}$/; // Supports (+ or 00) followed by 1 to 3 digits

  if (!countryCodePattern.test(countryCode)) {
    return 'Invalid format: country code should start with + or 00 followed by 1 to 3 digits';
  }
  return null; // Valid country code
};

// Validates website URL
export const validateWebsite = (website: string): string | null => {
  const urlPattern = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/\S*)?$/;

  if (!urlPattern.test(website)) {
    return 'Invalid website format';
  }
  return null; // Valid website
};
