export const incrementCounter = async (event: string) => {
  let url = '';

  // Set the URL for the appropriate event
  if (event === 'LandingPage') {
    url = 'https://counters.launcherscanner.workers.dev/increment-landing-page';
  } else if (event === 'IAC_2024') {
    url = 'https://counters.launcherscanner.workers.dev/increment-IAC';
  } else if (event === 'ConfirmationDialog') {
    url = 'https://counters.launcherscanner.workers.dev/increment-confirmation-dialog';
  }

  if (url) {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Failed to increment ${event} counter`);
      }
      console.log(`${event} counter incremented`);
    } catch (error) {
      console.error(`Error incrementing ${event} counter:`, error);
    }
  }
};
