import React from 'react';
import '../styles/HowItWorksSection.css';
import howItWorksImage_p1 from '../../../assets/howitworks_p1.png'; // Ensure the correct image path
import howItWorksImage_p2 from '../../../assets/howitworks_p2.png'; // Ensure the correct image path

const HowItWorksSection: React.FC = () => {
  return (
    <section id="how-it-works" className="how-it-works-section">
      <div className="container">
        <h2>Get Registered. <br/> We Handle the Details.</h2>
        <div className="how-it-works-image-wrapper">
          <img src={howItWorksImage_p1} alt="How It Works Step 1" className="how-it-works-image p1" />
          <img src={howItWorksImage_p2} alt="How It Works Step 2" className="how-it-works-image p2" />
        </div>
      </div>
    </section>
  );
};

export default HowItWorksSection;
