import React, { createContext, useState, ReactNode, useContext } from 'react';
import bcrypt from 'bcryptjs';

// Define the AuthContext type
interface AuthContextType {
  isAuthenticated: boolean;
  login: (password: string) => boolean;
}

// Create and export AuthContext
export const AuthContext = createContext<AuthContextType | null>(null);

interface AuthProviderProps {
  children: ReactNode;
}

// Access the hashed password from the environment variable
const hashedPassword = import.meta.env.VITE_LOGIN_PASSWORD;

if (!hashedPassword) {
  throw new Error("LOGIN_PASSWORD environment variable is not defined");
}

// Create and export AuthProvider component
export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  const login = (password: string): boolean => {
    // Compare the entered password with the hashed password
    const isPasswordCorrect = bcrypt.compareSync(password, hashedPassword); // Compare entered password with the hashed one
    if (isPasswordCorrect) {
      setIsAuthenticated(true);
      return true; // Return true when login is successful
    }
    return false;  // Return false when login fails
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login }}>
      {children}
    </AuthContext.Provider>
  );
};

// Export the custom hook to use the AuthContext
export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;  // Returns both `isAuthenticated` and `login`
}
