
type SetState<T> = React.Dispatch<React.SetStateAction<T>>;

export class ConfirmationDialogService {
  private setShowPrivacyPolicy: SetState<boolean>;
  private setShowTerms: SetState<boolean>;
  private setPrivacyContent: SetState<string | null>;
  private setTermsContent: SetState<string | null>;
  private setLoadingPrivacy: SetState<boolean>;
  private setLoadingTerms: SetState<boolean>;
  private setCanAcceptPrivacy: SetState<boolean>;
  private setCanAcceptTerms: SetState<boolean>;
  private setShowErrorDialog: SetState<boolean>;
  private setErrorMessage: SetState<string>;
  
  private inputCode: string[];
  private generatedCode: string;
  private consentShareDetails: boolean;
  private consentRecontact: boolean;
  private consentPrivacyPolicy: boolean;
  private consentTerms: boolean;
  private privacyRef: React.RefObject<HTMLDivElement>;
  private termsRef: React.RefObject<HTMLDivElement>;

  constructor(
    setShowPrivacyPolicy: SetState<boolean>,
    setShowTerms: SetState<boolean>,
    setPrivacyContent: SetState<string | null>,
    setTermsContent: SetState<string | null>,
    setLoadingPrivacy: SetState<boolean>,
    setLoadingTerms: SetState<boolean>,
    setCanAcceptPrivacy: SetState<boolean>,
    setCanAcceptTerms: SetState<boolean>,
    setShowErrorDialog: SetState<boolean>,
    setErrorMessage: SetState<string>,
    inputCode: string[],
    generatedCode: string,
    consentShareDetails: boolean,
    consentRecontact: boolean,
    consentPrivacyPolicy: boolean,
    consentTerms: boolean,
    privacyRef: React.RefObject<HTMLDivElement>,
    termsRef: React.RefObject<HTMLDivElement>
  ) {
    this.setShowPrivacyPolicy = setShowPrivacyPolicy;
    this.setShowTerms = setShowTerms;
    this.setPrivacyContent = setPrivacyContent;
    this.setTermsContent = setTermsContent;
    this.setLoadingPrivacy = setLoadingPrivacy;
    this.setLoadingTerms = setLoadingTerms;
    this.setCanAcceptPrivacy = setCanAcceptPrivacy;
    this.setCanAcceptTerms = setCanAcceptTerms;
    this.setShowErrorDialog = setShowErrorDialog;
    this.setErrorMessage = setErrorMessage;

    this.inputCode = inputCode;
    this.generatedCode = generatedCode;
    this.consentShareDetails = consentShareDetails;
    this.consentRecontact = consentRecontact;
    this.consentPrivacyPolicy = consentPrivacyPolicy;
    this.consentTerms = consentTerms;
    this.privacyRef = privacyRef;
    this.termsRef = termsRef;
  }

  validateInputs() {
    if (this.inputCode.join('') !== this.generatedCode) {
      this.setErrorMessage('Incorrect code entered. Please try again.');
      return false;
    }
    if (
      !this.consentShareDetails ||
      !this.consentRecontact ||
      !this.consentPrivacyPolicy ||
      !this.consentTerms
    ) {
      this.setErrorMessage('You must agree to all terms and policies before proceeding.');
      return false;
    }
    return true;
  }

  handleProceedWithValidation(handleProceed: () => void) {
    if (this.validateInputs()) {
      handleProceed();
    } else {
      this.setShowErrorDialog(true);
    }
  }

  async openPrivacyPolicy(e: React.MouseEvent) {
    e.preventDefault();
    this.setLoadingPrivacy(true);
    try {
      const response = await fetch('/privacy.html');
      const text = await response.text();
      this.setPrivacyContent(text);
      this.setLoadingPrivacy(false);
      this.setShowPrivacyPolicy(true);
    } catch (error) {
      console.error('Error fetching privacy policy:', error);
      this.setLoadingPrivacy(false);
    }
  }

  async openTerms(e: React.MouseEvent) {
    e.preventDefault();
    this.setLoadingTerms(true);
    try {
      const response = await fetch('/terms.html');
      const text = await response.text();
      this.setTermsContent(text);
      this.setLoadingTerms(false);
      this.setShowTerms(true);
    } catch (error) {
      console.error('Error fetching terms:', error);
      this.setLoadingTerms(false);
    }
  }

  handlePrivacyScroll() {
    if (this.privacyRef.current) {
      const { scrollHeight, scrollTop, clientHeight } = this.privacyRef.current;
      this.setCanAcceptPrivacy(scrollTop + clientHeight >= scrollHeight - 10);
    }
  }

  handleTermsScroll() {
    if (this.termsRef.current) {
      const { scrollHeight, scrollTop, clientHeight } = this.termsRef.current;
      this.setCanAcceptTerms(scrollTop + clientHeight >= scrollHeight - 10);
    }
  }

  goBackToConfirmation() {
    this.setShowPrivacyPolicy(false);
    this.setShowTerms(false);
  }

  acceptPrivacyPolicy(setConsentPrivacyPolicy: (value: boolean) => void) {
    setConsentPrivacyPolicy(true);
    this.goBackToConfirmation();
  }

  acceptTerms(setConsentTerms: (value: boolean) => void) {
    setConsentTerms(true);
    this.goBackToConfirmation();
  }
}
