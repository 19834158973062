import React from 'react';
import '../styles/HeroSection.css'; // Ensure the CSS file is linked

const HeroSection: React.FC = () => {
  const handleScrollToEarlyAccess = () => {
    const earlyAccessSection = document.getElementById('early-access');
    if (earlyAccessSection) {
      earlyAccessSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <section id="hero" className="hero">
      <div className="container">
        <div className="hero-content-wrapper">
          {/* Text content on the left */}
          <div className="hero-content">
            <p className="hero-description" >
              Your gateway to optimal payload <br />
              launches. Maximize your Mission <br />
              success with Launcherscanner's <br />
              advanced payload matching and <br />
              launch optimization platform.
            </p>

            {/* Button with click handler inside the hero-content */}
            <button onClick={handleScrollToEarlyAccess} className="cta-btn">
              Get Early Access
            </button>
          </div>

          {/* Embed YouTube video on the right */}
          {/* <div className="hero-video">
            <iframe
              src="https://www.youtube.com/embed/ihFTHjFgM9Q?autoplay=1&loop=1&mute=1&playlist=ihFTHjFgM9Q"
              title="Launcherscanner Video"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
