// ConfirmationDialog.logics.ts
import { useState, useEffect } from 'react';
import {  ConfirmationDialogLogic } from '../../../types/types'; // Moved types


export const useConfirmationLogic = (
  isOpen: boolean,
  onProceed: () => void,
  onClose: () => void,
): ConfirmationDialogLogic => {
  const generateRandomCode = () => Math.floor(100000 + Math.random() * 900000).toString();

  const [inputCode, setInputCode] = useState<string[]>(Array(6).fill(''));
  const [generatedCode, setGeneratedCode] = useState<string>(generateRandomCode());
  const [consentShareDetails, setConsentShareDetails] = useState<boolean>(false);
  const [consentRecontact, setConsentRecontact] = useState<boolean>(false);
  const [consentPrivacyPolicy, setConsentPrivacyPolicy] = useState<boolean>(false);
  const [consentTerms, setConsentTerms] = useState<boolean>(false);
  const [timer, setTimer] = useState<number>(10);
  const [codeErrorMessage, setCodeErrorMessage] = useState<string>('');
  const [isPrivacyClicked, setIsPrivacyClicked] = useState<boolean>(false);
  const [isTermsClicked, setIsTermsClicked] = useState<boolean>(false);

  useEffect(() => {
    if (isOpen) {
      setGeneratedCode(generateRandomCode());
      setInputCode(Array(6).fill(''));
      setConsentShareDetails(false);
      setConsentRecontact(false);
      setConsentPrivacyPolicy(false);
      setConsentTerms(false);
      setIsPrivacyClicked(false);
      setIsTermsClicked(false);
      setTimer(600);

      const countdown = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer === 1) {
            onClose(); // Close when the timer reaches 1
          }
          return prevTimer > 0 ? prevTimer - 1 : 0;
        });
      }, 1000);
  
      return () => clearInterval(countdown);
    }
  }, [isOpen, onClose]);

  const handleDigitChange = (index: number, value: string) => {
    setInputCode((prev) => {
      const newCode = [...prev];
      newCode[index] = value;
      return newCode;
    });
  };

  const handleFocusNext = (index: number) => {
    const nextInput = document.getElementById(`digit-${index + 1}`);
    if (nextInput) {
      (nextInput as HTMLInputElement).focus();
    }
  };

  const handlePrivacyClick = () => {
    window.open('/privacy.html', '_blank');
    setIsPrivacyClicked(true);
  };

  const handleTermsClick = () => {
    window.open('/terms.html', '_blank');
    setIsTermsClicked(true);
  };

  const readAloudCode = () => {
    const utterance = new SpeechSynthesisUtterance(`Your confirmation code is ${generatedCode.split('').join(' ')}`);
    utterance.lang = 'en-US'; // Set the language to English (United States)
    window.speechSynthesis.speak(utterance);
  };

  const handleProceed = () => {
    if (!consentShareDetails || !consentRecontact ) {
      alert('You must consent to sharing your details, being recontacted, and agree to the Privacy Policy and Terms.');
      return;
    }
    if (!consentPrivacyPolicy || !consentTerms) {
      alert('You must click and view the Privacy Policy and Terms and Conditions before proceeding.');
      return;
    }
    if (inputCode.join('') !== generatedCode) {
      setCodeErrorMessage('The confirmation code is incorrect.');
      return;
    }
    setCodeErrorMessage('');
    onProceed();
  };

  return {
    inputCode,
    generatedCode,
    consentShareDetails,
    consentRecontact,
    consentPrivacyPolicy,
    consentTerms,
    timer,
    codeErrorMessage,
    isPrivacyClicked,
    isTermsClicked,
    handleDigitChange,
    handleFocusNext,
    handlePrivacyClick,
    handleTermsClick,
    handleProceed,
    readAloudCode,
    setConsentShareDetails,
    setConsentRecontact,
    setConsentPrivacyPolicy,
    setConsentTerms,
  };
};
