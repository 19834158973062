import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { useParams } from 'react-router-dom';

import '../styles/StickyHeader.css';
import logo from '../../../assets/logo.png';

const StickyHeader: React.FC = () => {
  const { eventId } = useParams<{ eventId: string }>(); // Capture eventId from URL
  const eventIdPath = eventId ? `/eventId/${eventId}` : ''; // Use eventId if available, otherwise default to empty

  return (
    <header className="sticky-header">
      <div className="container">
        <nav>
          <div className="logo-container">
            <HashLink smooth to={`${eventIdPath}#hero`} className="logo-container">
              <img src={logo} alt="Launcherscanner Logo" className="logo-image" />
              <span className="logo-text">LAUNCHERSCANNER</span>
            </HashLink>
          </div>
          <ul className="nav-links">
            <li><HashLink smooth to={`${eventIdPath}#hero`}>Home</HashLink></li>
            <li><HashLink smooth to={`${eventIdPath}#how-it-works`}>How it Works</HashLink></li>
            <li><HashLink smooth to={`${eventIdPath}#early-access`}>Early Access</HashLink></li>
            <li><HashLink smooth to={`${eventIdPath}#features`}>Features</HashLink></li>
            <li><HashLink smooth to={`${eventIdPath}#early-access`}>Contact</HashLink></li>
            <li><a href="/documents">Documents</a></li> {/* Standard link for Documents */}
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default StickyHeader;
