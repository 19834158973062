import React, { useState, useRef } from 'react';
import { EarlyAccessData } from '../../../types/types';
import { useConfirmationLogic } from '../logic/ConfirmationDialog.logics';
import { ConfirmationDialogService } from '../services/ConfirmationDialogService';
import '../styles/ConfirmationDialog.css';
import { useDisableScroll } from '../../../hooks/useDisableScroll';
import { useIncrementCounter } from '../../../hooks/useIncrementCounter';
import { useKeyDownListener } from '../../../hooks/useKeyDownListener';

interface ConfirmationDialogProps {
  isOpen: boolean;
  formData: EarlyAccessData;
  onProceed: () => void;
  onClose: () => void;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({ isOpen, formData, onProceed, onClose }) => {
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [privacyContent, setPrivacyContent] = useState<string | null>(null);
  const [termsContent, setTermsContent] = useState<string | null>(null);
  const [loadingPrivacy, setLoadingPrivacy] = useState(false);
  const [loadingTerms, setLoadingTerms] = useState(false);
  const [canAcceptPrivacy, setCanAcceptPrivacy] = useState(false);
  const [canAcceptTerms, setCanAcceptTerms] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const privacyRef = useRef<HTMLDivElement>(null);
  const termsRef = useRef<HTMLDivElement>(null);

  const {
    inputCode,
    generatedCode,
    consentShareDetails,
    consentRecontact,
    consentPrivacyPolicy,
    consentTerms,
    timer,
    codeErrorMessage,
    handleDigitChange,
    handleFocusNext,
    handleProceed,
    readAloudCode,
    setConsentShareDetails,
    setConsentRecontact,
    setConsentPrivacyPolicy,
    setConsentTerms,
  } = useConfirmationLogic(isOpen, onProceed, onClose);

  useDisableScroll(showPrivacyPolicy, showTerms);
  useIncrementCounter();
  useKeyDownListener(onClose, handleProceed);
  
  // Instantiate the service and pass required states and functions
  const service = new ConfirmationDialogService(
    setShowPrivacyPolicy,
    setShowTerms,
    setPrivacyContent,
    setTermsContent,
    setLoadingPrivacy,
    setLoadingTerms,
    setCanAcceptPrivacy,
    setCanAcceptTerms,
    setShowErrorDialog,
    setErrorMessage,
    inputCode,
    generatedCode,
    consentShareDetails,
    consentRecontact,
    consentPrivacyPolicy,
    consentTerms,
    privacyRef,
    termsRef
  );

  const handleProceedWithValidation = () => service.handleProceedWithValidation(handleProceed);

  if (!isOpen) return null;

  return (
    <div className="confirm-dialog-overlay">
      <div className="dialog-content" style={{ width: '600px', margin: 'auto', maxHeight: showPrivacyPolicy || showTerms ? 'none' : '90vh', overflowY: showPrivacyPolicy || showTerms ? 'hidden' : 'auto' }}>
        {showPrivacyPolicy ? (
          <>
            <h3>Privacy Policy</h3>
            <div className="privacy-policy-content" ref={privacyRef} style={{ maxHeight: '70vh', overflowY: 'auto' }} onScroll={() => service.handlePrivacyScroll()}>
              {loadingPrivacy ? <p>Loading...</p> : <div dangerouslySetInnerHTML={{ __html: privacyContent || '' }} />}
            </div>
            <div className="dialog-buttons">
              <button onClick={() => service.acceptPrivacyPolicy(setConsentPrivacyPolicy)} className="accept-btn" disabled={!canAcceptPrivacy}>Accept</button>
              <button onClick={() => service.goBackToConfirmation()} className="back-btn">Back</button>
              <p><i> (scroll until the end to Accept)</i> </p>
            </div>
          </>
        ) : showTerms ? (
          <>
            <h3>Terms and Conditions</h3>
            <div className="terms-content" ref={termsRef} style={{ maxHeight: '70vh', overflowY: 'auto' }} onScroll={() => service.handleTermsScroll()}>
              {loadingTerms ? <p>Loading...</p> : <div dangerouslySetInnerHTML={{ __html: termsContent || '' }} />}
            </div>
            <div className="dialog-buttons">
              <button onClick={() => service.acceptTerms(setConsentTerms)} className="accept-btn" disabled={!canAcceptTerms}>Accept</button>
              <button onClick={() => service.goBackToConfirmation()} className="back-btn">Back</button>
              <p><i> (scroll until the end to Accept)</i> </p>
            </div>
          </>
        ) : (
          <>
            <h3>Please Review Your Information</h3>
            <table className="confirmation-table">
              <tbody>
                <tr><td>Company Name</td><td>{formData.companyName}</td></tr>
                <tr><td>Website</td><td>{formData.website}</td></tr>
                <tr><td>Industry</td><td>{formData.industry}</td></tr>
                <tr><td>Region</td><td>{formData.region}</td></tr>
                <tr><td>Country</td><td>{formData.country}</td></tr>
                <tr><td>Title</td><td>{formData.title}</td></tr>
                <tr><td>First Name</td><td>{formData.firstName}</td></tr>
                <tr><td>Surname</td><td>{formData.surname}</td></tr>
                <tr><td>Job Title</td><td>{formData.jobRole}</td></tr>
                <tr><td>Email</td><td>{formData.email}</td></tr>
                <tr><td>Telephone</td><td>{formData.countryCode} {formData.telephone}</td></tr>
                {formData.eventId && (<tr><td>Event ID</td><td>{formData.eventId}</td></tr>)}
              </tbody>
            </table>

            <label htmlFor="message">Your Message:</label>
            <textarea id="message" className="message-textarea" value={formData.message} readOnly />

            <div className="checkbox-section">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={consentShareDetails}
                  onChange={(e) => setConsentShareDetails(e.target.checked)}
                />
                I consent to sharing my details with Launcherscanner.
              </label>

              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={consentRecontact}
                  onChange={(e) => setConsentRecontact(e.target.checked)}
                />
                I consent to being recontacted by Launcherscanner.
              </label>

              <label className="checkbox-label tooltip-container">
                <input
                  type="checkbox"
                  checked={consentPrivacyPolicy}
                  disabled
                />
                <span className="tooltip-text">Click on "Privacy Policy" to read and accept</span>
                I have read and agree to the
                <a href="#" onClick={(e) => service.openPrivacyPolicy(e)} className="policy-link">Privacy Policy</a>.
              </label>

              <label className="checkbox-label tooltip-container">
                <input
                  type="checkbox"
                  checked={consentTerms}
                  disabled
                />
                <span className="tooltip-text">Click on "Terms & Conditions" to read and accept</span>
                I have read and agree to the
                <a href="#" onClick={(e) => service.openTerms(e)} className="policy-link">Terms & Conditions</a>.
              </label>

            </div>

            <label className="code-label">Please input this number to confirm you are not a robot:</label>
            <div className="code-input-row">
              <div className="code-digits">
                {generatedCode.split('').map((digit: string, index: number) => (
                  <span key={index} className="code-digit">{digit}</span>
                ))}
              </div>

              <div className="input-digits">
                {[...Array(6)].map((_, index) => (
                  <input
                    key={index}
                    id={`digit-${index}`}
                    type="text"
                    maxLength={1}
                    value={inputCode[index] || ''}
                    onChange={(e) => handleDigitChange(index, e.target.value)}
                    onInput={() => handleFocusNext(index)}
                    className="digit-input"
                  />
                ))}
              </div>

              <button className="read-aloud-btn" onClick={readAloudCode}>
                <i className="fa fa-volume-up" aria-hidden="true"></i> 📣
              </button>
            </div>

            {codeErrorMessage && <div className="error-message" style={{ color: 'red' }}>{codeErrorMessage}</div>}
            <div className="timer">Time before closing: {timer}s</div>

            <div className="dialog-buttons">
              <button onClick={onClose} className="cancel-btn">Cancel</button>
              <button onClick={handleProceedWithValidation} className="confirm-btn">Confirm and Send</button>
            </div>
          </>
        )}
      </div>

      {showErrorDialog && (
        <div className="error-dialog-overlay">
          <div className="error-dialog">
            <h3>Error</h3>
            <p>{errorMessage}</p>
            <button onClick={() => setShowErrorDialog(false)} className="close-btn">Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ConfirmationDialog;
