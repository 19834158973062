import React, {useEffect} from 'react';
import { useParams } from 'react-router-dom';
import HeroSection from '../features/EarlyAccess/components/HeroSection';
import HowItWorksSection from '../features/EarlyAccess/components/HowItWorksSection';
import EarlyAccessSection from '../features/EarlyAccess/components/EarlyAccessSection';
import FeaturesSection from '../features/EarlyAccess/components/FeaturesSection';
import { incrementCounter } from '../services/CounterService';


const LandingPage: React.FC = () => {
  const { eventId } = useParams<{ eventId: string }>(); // Capture eventId from the URL

  useEffect(() => {
    // Increment the LandingPage counter any time the page is viewed
    incrementCounter('LandingPage');
    
    // Increment the IAC_2024 counter if the eventId matches IAC_2024
    if (eventId === 'IAC_2024') {
      incrementCounter('IAC_2024');
    }
  }, [eventId]); // Run this effect when eventId changes

  return (
    <div>
      <HeroSection />
      <HowItWorksSection />
      <EarlyAccessSection eventId={eventId} />
      <FeaturesSection />
    </div>
  );
};

export default LandingPage;
