import React, { useState, useEffect } from 'react';
import EarlyAccessForm from './EarlyAccessForm';
import ConfirmationDialog from './ConfirmationDialog';
import { EarlyAccessService } from '../services/EarlyAccessService';
import { EarlyAccessData } from '../../../types/types';
import '../styles/EarlyAccessSection.css';

interface EarlyAccessSectionProps {
  eventId?: string;
}

const EarlyAccessSection: React.FC<EarlyAccessSectionProps> = ({ eventId }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [submittedData, setSubmittedData] = useState<EarlyAccessData | null>(null);
  const [validEventId, setValidEventId] = useState<string | undefined>(eventId);
  const [errorDialog, setErrorDialog] = useState<string | null>(null);
  const [isError, setIsError] = useState(false);

  const earlyAccessService = new EarlyAccessService(
    setIsDialogOpen,
    setResponseMessage,
    setSubmittedData,
    setIsError,
    setValidEventId,
    setErrorDialog
  );

  useEffect(() => {
    if (eventId) {
      earlyAccessService.validateEventId(eventId);
    }
  }, [eventId]);

  const handleFormSubmitWithEventId = (formData: EarlyAccessData) => {
    const updatedFormData = { ...formData, eventId: validEventId };
    earlyAccessService.handleFormSubmit(updatedFormData, validEventId);
  };

  const containerClass = responseMessage && !isError
    ? 'early-access-container container-disabled'
    : 'early-access-container container-enabled';

  return (
    <section id="early-access" className="early-access">
      <div className={containerClass}>
        {!responseMessage ? (
          <>
            {errorDialog && (
              <div className="error-dialog">
                <p>{errorDialog}</p>
                <button onClick={() => setErrorDialog(null)}>Close</button>
              </div>
            )}
            <EarlyAccessForm handleFormSubmit={handleFormSubmitWithEventId} eventId={validEventId} />
            {submittedData && (
              <ConfirmationDialog
                isOpen={isDialogOpen}
                formData={submittedData}
                onProceed={() => earlyAccessService.handleProceed(submittedData)}
                onClose={() => setIsDialogOpen(false)}
              />
            )}
          </>
        ) : (
          <div dangerouslySetInnerHTML={{ __html: responseMessage }} />
        )}
      </div>
    </section>
  );
};

export default EarlyAccessSection;
