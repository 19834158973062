import { useState, useCallback } from 'react';
import { validateEmail, validateTelephone, validateCountryCode, validateWebsite } from '../../../middleware/validationMiddleware';
import { EarlyAccessData, initializeEarlyAccessData } from '../../../types/types';
import { Option, regionCountryOptions } from '../../../data/optionsLists';

// Define useEarlyAccessFormLogic locally within this file
export const useEarlyAccessFormLogic = () => {
  const [formData, setFormData] = useState<EarlyAccessData>(initializeEarlyAccessData());
  const [confirmEmail, setConfirmEmail] = useState('');
  const [emailError, setEmailError] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === 'email' || name === 'confirmEmail') {
      checkEmailMatch(name === 'email' ? value : formData.email, name === 'confirmEmail' ? value : confirmEmail);
    }
  };

  const handleConfirmEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setConfirmEmail(value);
    checkEmailMatch(formData.email, value);
  };

  const checkEmailMatch = (email: string, confirmEmail: string) => {
    setEmailError(email !== confirmEmail);
  };

  return {
    formData,
    setFormData,
    confirmEmail,
    handleConfirmEmailChange,
    emailError,
    handleChange,
  };
};

// useFormHandlers function remains as is, using the locally defined useEarlyAccessFormLogic
export const useFormHandlers = (handleFormSubmit: (formData: any) => void, eventId: string | undefined) => {
  const { formData, handleChange, confirmEmail, handleConfirmEmailChange } = useEarlyAccessFormLogic();
  const [emailError, setEmailError] = useState<string | null>(null);
  const [showTooltip, setShowTooltip] = useState<{ [key: string]: string | null }>({});
  const [selectedRegion, setSelectedRegion] = useState<string>('');
  const charLimit = 50;
  const messageCharLimit = 2000;
  const [messageCharCount, setMessageCharCount] = useState(formData.message.length);

  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();

      if (eventId) {
        // Minimal validation if eventId is present
        const emailValidationError = validateEmail(formData.email);
        const confirmEmailError = confirmEmail && formData.email !== confirmEmail ? 'Emails do not match' : '';

        setEmailError(emailValidationError);
        setShowTooltip({ email: emailValidationError, confirmEmail: confirmEmailError });

        if (!emailValidationError && !confirmEmailError) {
          handleFormSubmit(formData); // Proceed if email and confirmEmail are valid
        }
      } else {
        // Full validation if eventId is not present
        const missingFields: { [key: string]: string | null } = {
          companyName: formData.companyName ? null : 'Company name is required',
          website: validateWebsite(formData.website),
          industry: formData.industry ? null : 'Industry is required',
          region: formData.region ? null : 'Region is required',
          country: formData.country ? null : 'Country is required',
          title: formData.title ? null : 'Title is required',
          firstName: formData.firstName ? null : 'First name is required',
          surname: formData.surname ? null : 'Surname is required',
          jobRole: formData.jobRole ? null : 'Job role is required',
          email: validateEmail(formData.email),
          confirmEmail: formData.email === confirmEmail ? null : 'Emails do not match',
          telephone: validateTelephone(formData.telephone),
          countryCode: validateCountryCode(formData.countryCode),
          message: formData.message ? null : 'Message is required',
        };

        setEmailError(missingFields.email);
        setShowTooltip(missingFields);

        if (Object.values(missingFields).every((error) => error === null)) {
          handleFormSubmit(formData); // Proceed only if all fields are valid
        }
      }
    },
    [formData, confirmEmail, handleFormSubmit, eventId] // Dependency array ensures handleSubmit always uses the latest eventId
  );

  const renderTooltip = (field: string) => {
    if (showTooltip[field]) {
      return <span className="tooltip">{showTooltip[field]}</span>;
    }
    return null;
  };

  const handleRegionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    handleChange(e);
    setSelectedRegion(e.target.value);
  };

  const renderCountriesForSelectedRegion = () => {
    const regionData = regionCountryOptions.find((region) => region.region === selectedRegion);
    if (regionData) {
      return regionData.countries.map((country: Option) => (
        <option key={country.value} value={country.label}>
          {country.label}
        </option>
      ));
    }
    return null;
  };

  const isCountryDisabled = !selectedRegion;

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    handleChange(e);
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    if (name === 'message') {
      if (value.length <= messageCharLimit) {
        setMessageCharCount(value.length);
        handleChange(e);
      }
    } else if (value.length <= charLimit) {
      handleChange(e);
    }
  };

  const handlers = {
    confirmEmail,
    handleConfirmEmailChange,
    handleSubmit,
    renderTooltip,
    handleRegionChange,
    renderCountriesForSelectedRegion,
    handleSelectChange,
    handleTextChange,
    messageCharCount,
    messageCharLimit,
    charLimit,
    isCountryDisabled,
    emailError,
  };

  return { formData, handlers };
};
