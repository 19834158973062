import React from 'react';
import '../styles/FooterSection.css';

interface FooterSectionProps {
  extended: boolean;
}

const FooterSection: React.FC<FooterSectionProps> = ({ extended }) => {
  return (
    <footer id="footer" className="footer">
      {extended && (
        <div className="container footer-container">
          {/* Company Section */}
          <div className="footer-column">
            <h4>Company</h4>
            <ul>
              <li><a href="/about">About Us</a></li>
              <li><a href="/careers">Careers</a></li>
              <li><a href="/press">Press</a></li>
              <li><a href="/blog">Blog</a></li>
            </ul>
          </div>

          {/* Resources Section */}
          <div className="footer-column">
            <h4>Resources</h4>
            <ul>
              <li><a href="/documentation">Documentation</a></li>
              <li><a href="/api">API</a></li>
              <li><a href="/support">Support</a></li>
            </ul>
          </div>

          {/* Contact Section */}
          <div className="footer-column">
            <h4>Contact</h4>
            <ul>
              <li><a href="#early-access">Contact Form</a></li>
            </ul>
          </div>

          {/* Office Address Section */}
          <div className="footer-column">
            <h4>Office</h4>
            <address>
              LAUNCHERSCANNER S.r.l.<br />
              Via Giovanni Durando, 39<br />
              20158 Milano, MI, Italy
            </address>
          </div>

          {/* Social Media Section */}
          <div className="footer-column">
            <h4>Follow Us</h4>
            <ul className="social-links">
              <li><a href="https://linkedin.com/company/launcherscanner" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
            </ul>
          </div>
        </div>
      )}

      {/* Footer Bottom Section */}
      <div className="footer-bottom">
        <p>
          &copy; {new Date().getFullYear()} LAUNCHERSCANNER S.r.l. | 
          <a href="/privacy.html" target="_blank" rel="noopener noreferrer"> Privacy Notice</a> | 
          <a href="/terms.html" target="_blank" rel="noopener noreferrer"> Terms and Conditions</a> | 
          <a href="/cookie-preferences.html" target="_blank" rel="noopener noreferrer"> Cookie Preferences</a>
        </p>
      </div>
    </footer>
  );
};

export default FooterSection;
