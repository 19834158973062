import React from 'react';
import '../styles/FeaturesSection.css';

const FeaturesSection: React.FC = () => {
  return (
    <section id="features" className="features-section">
      <div className="container">
        <h2>Features</h2>
        <div className="features-grid">
          <div className="feature-item">
            <h3>Exclusive Access</h3>
            <p>Join a select network of satellite operators and launch providers with invitation-only membership.</p>
          </div>
          <div className="feature-item">
            <h3>Targeted Matchmaking</h3>
            <p>Connect payloads and launchers for rocketry, stratospheric, and orbital missions.</p>
          </div>
          <div className="feature-item">
            <h3>Optimized Solutions</h3>
            <p>Benefit from cost-effective rideshare opportunities across various orbits.</p>
          </div>
          <div className="feature-item">
            <h3>Seamless Integration</h3>
            <p>Easily integrate our platform into your existing workflows for a smooth user experience.</p>
          </div>
          <div className="feature-item">
            <h3>Grow and Optimize</h3>
            <p>Engage with a growing network of trusted partners to expand and refine your services.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
