import React from 'react';
import MinimalEarlyAccessForm from './MinimalEarlyAccessForm';
import FullEarlyAccessForm from './FullEarlyAccessForm';

interface EarlyAccessFormProps {
  handleFormSubmit: (formData: any) => void;
  eventId?: string; // Allow `eventId` to be undefined
}

const EarlyAccessForm: React.FC<EarlyAccessFormProps> = ({ handleFormSubmit, eventId }) => {
  console.log('Event ID in EarlyAccessForm:', eventId); // Check if eventId is correctly passed

  return (
    <>
      {eventId ? (
        <MinimalEarlyAccessForm handleFormSubmit={handleFormSubmit} eventId={eventId} />
      ) : (
        <FullEarlyAccessForm handleFormSubmit={handleFormSubmit} />
      )}
    </>
  );
};

export default EarlyAccessForm;
