// types.tsx
export interface EarlyAccessData {
  companyName: string;
  website: string;
  industry: string;
  region: string;
  country: string;
  title: string;               
  firstName: string;           
  surname: string;             
  jobRole: string;            
  email: string;
  confirmEmail: string;         
  countryCode: string;  
  telephone: string;
  message: string;
  consentShareDetails: boolean;
  consentRecontact: boolean;
  eventId?: string;  

}

// Function to initialize the form data
export const initializeEarlyAccessData = (): EarlyAccessData => ({
  companyName: '',
  website: '',
  industry: '',
  region: '',
  country: '',
  title: '',                   
  firstName: '',               
  surname: '',                 
  jobRole: '',                
  email: '',
  confirmEmail: '',            
  countryCode: '',     
  telephone: '',
  message: '',
  consentShareDetails: false,
  consentRecontact: false,
  eventId: undefined, 
});





export interface ConfirmationDialogLogic {
  inputCode: string[];
  generatedCode: string;
  consentShareDetails: boolean;
  consentRecontact: boolean;
  consentPrivacyPolicy: boolean;
  consentTerms: boolean;
  timer: number;
  codeErrorMessage: string;
  isPrivacyClicked: boolean;
  isTermsClicked: boolean;
  handleDigitChange: (index: number, value: string) => void;
  handleFocusNext: (index: number) => void;
  handlePrivacyClick: () => void;
  handleTermsClick: () => void;
  handleProceed: () => void;
  readAloudCode: () => void;
  setConsentShareDetails: (value: boolean) => void;
  setConsentRecontact: (value: boolean) => void;
  setConsentPrivacyPolicy: (value: boolean) => void;
  setConsentTerms: (value: boolean) => void;
}
